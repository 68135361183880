import React, {useState} from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { ListViewItem } from '@common';
import Seo from "@components/Seo";
import { useTranslation } from 'react-i18next'
import Header from '../components/common/menus/header';


const PageWrapper = styled.div`
  padding:5%;
  padding-top:90px;
  background-color: ${props => props.theme.backgroundColor};
  min-height: 100vh;
  @media (max-width: 320px) {
    padding:5% 2.5%;
  }
`
const ToggleWrapper = styled.div`
  margin-bottom:30px;
  @media (max-width: 320px) {
    margin-bottom:15px;
  }
  display:flex;
  justify-content: center;
`
const CenterWrapper = styled.div`
  width:100%;
`
const Toggle = styled.div`
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
`

const ToggleOption = styled.p`
  position: relative;
  font-size:10px;
  display:inline-block;
  color: ${props => props.selected ?  "#fff": props.theme.secondaryColor};
  border-radius: 20px;
  text-transform:uppercase;
  padding: 5px 10px;
  margin:0px;
  z-index:10;
  user-select:none;
  @media (min-width:767px) {
    font-size:12px;
  }
`

const ActiveItemIndicator = styled.div`
  position:absolute;
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 20px;
  width:${props => props.width.mobile}px;
  left:${props => props.left.mobile}px;

  @media (min-width:767px) {
    font-size:12px;
    width:${props => props.width.desktop}px;
    left:${props => props.left.desktop}px;
  }

  transition:all 0.4s ease-in-out;
  height:100%;
  top:0px;
  z-index:1;
  user-select:none;
`

const getFilterGenre = (index) =>{
  switch (index) {
    case 0:
      return "Guided Sex"
    case 1:
      return "Guided Masturbation"
    case 2:
      return "Affirmation"
    default:
      return "Guided Masturbation"
  }
}


const indicatorWidths = {
    en:[
      {
        left:{mobile:0,desktop:0},
        width:{mobile:76,desktop:88}
      },
      {
        left:{mobile:76,desktop:88},
        width:{mobile:134,desktop:156}
      },
      {
        left:{mobile:210,desktop:244},
        width:{mobile:89,desktop:104}
      },
    ],
    de:[
      {
        left:{mobile:0,desktop:0},
        width:{mobile:70,desktop:78}
      },
      {
        left:{mobile:70,desktop:78},
        width:{mobile:127,desktop:150}
      },
      {
        left:{mobile:197,desktop:228},
        width:{mobile:83,desktop:95}
      },
    ],
}

const GuidesSection = (props) => {
  const [SelectedIndex, setSelectedIndex] = useState(1)
  const { t } = useTranslation(['pages'])
  const {allStory} = props.data
  const lang = props.pageContext.lang


  return (
    <>
      <Header location={props.location}/>
      <Seo 
        title={t('pages:guides.pageTitle')}
        description={t('pages:guides.metaDescription')}
      />
      <PageWrapper>
        <CenterWrapper>
          <ToggleWrapper>
          {lang !== "es" &&
            <Toggle>
              <ActiveItemIndicator selectedIndex={SelectedIndex} left={indicatorWidths[lang][SelectedIndex].left} width={indicatorWidths[lang][SelectedIndex].width}/>
              <ToggleOption selected={SelectedIndex === 0} onClick={() => setSelectedIndex(0)}>{t('pages:guides.guidedSex')}</ToggleOption>
              <ToggleOption selected={SelectedIndex === 1} onClick={() => setSelectedIndex(1)}>{t('pages:guides.guidedMasturbation')}</ToggleOption>
              <ToggleOption selected={SelectedIndex === 2} onClick={() => setSelectedIndex(2)}>{t('pages:guides.affirmation')}</ToggleOption>
            </Toggle>
          }
          </ToggleWrapper>
          {allStory.edges
            .filter((story) => (story.node.genre.en.genre === getFilterGenre(SelectedIndex) )&& story.node[lang].published)
            .sort((storyA, storyB) => new Date(storyB.node[lang].dateCreated) - new Date(storyA.node[lang].dateCreated))
            .map((edge) => (
              <ListViewItem 
                id={edge.node.id}
                slug={edge.node[lang].slug}
                title={edge.node[lang].title}
                premiumAudioRef={edge.node[lang].premiumAudioRef}
                sampleAudioRef={edge.node[lang].sampleAudioRef}
                freeAudioRef={edge.node[lang].freeAudioRef}
                storyCover={edge.node.localImage.childImageSharp.gatsbyImageData}
                thumbnail={edge.node.localImage.smallImage.gatsbyImageData}
                duration={edge.node[lang].duration}
                voices={edge.node[lang].voices}
                mainFantasy={edge.node.mainFantasy[lang].fantasy}
                spiciness={edge.node.spiciness}
                premiumContent={edge.node[lang].premiumContent}
                audiodesiresOriginal={edge.node.audiodesiresOriginal}
                key={edge.node.id}
              />
            ))
          }
        </CenterWrapper>
      </PageWrapper>
    </>
  )
}

export default GuidesSection

export const query = graphql`query GuidesQuery($lang: String) {
  allStory(filter: {languages: {in: [$lang]}}) {
    edges {
      node {
        audiodesiresOriginal
        id
        spiciness
        dateCreated
        en {
          duration
          featured
          premiumContent
          dateCreated
          sampleAudioRef
          published
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        es {
          duration
          featured
          premiumContent
          dateCreated
          published
          sampleAudioRef
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        de {
          duration
          featured
          premiumContent
          dateCreated
          published
          sampleAudioRef
          slug
          title
          voices{
            id
            name
            gender
          }
        }
        localImage {
          smallImage: childImageSharp {
            gatsbyImageData( placeholder: BLURRED,width: 100, height: 100, layout: CONSTRAINED)
          }
        }
        ...sliderImageLanding
        mainFantasy {
          id
          en {
            fantasy
          }
          es {
            fantasy
          }
          de {
            fantasy
          }
      
        }
        fantasies {
          id
          en {
            fantasy
            slug
          }
          es {
            fantasy
            slug
          }
          de {
            fantasy
            slug
          }
        }
        genre {
          id
          en {
            genre
          }
          es {
            genre
          }
          de {
            genre
          }
          id
        }
        scenario {
          en {
            scenario
            slug
          }
          es {
            scenario
            slug
          }
          de {
            scenario
            slug
          }
        }
      }
    }
  }
}`